import ApiService from "@/core/services/api.service";

export default {

    // -----API DATA PERSONAL------
    getPersonal() {
        ApiService.setHeader();
        return ApiService.get("v2/user/personal")
    },
    updatePersonal(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/user/personal", credentials)
    },
    getLogActivity() {
        ApiService.setHeader();
        return ApiService.get("v2/user/log-activity")
    },
    

    // -----API DATA ACCOUNT------
    // -----API ACCOUNT------
    getAccount() {
        ApiService.setHeader();
        return ApiService.get("v2/user/account")
    },
    updateAccount(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/user/account", credentials)
    },

    // -----API CHANGE PASSWORD------
    updateChangePass(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/user/change-password", credentials)
    },

    // -----API SETTING EMAIL------
    getEmailSetting() {
        ApiService.setHeader();
        return ApiService.get("v2/user/email-setting")
    },
    updateEmailSetting(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/user/email-setting", credentials)
    },


    // ------API DATA STATUS ACCOUNT-------
    statusEmail() {
        ApiService.setHeader();
        return ApiService.get("v2/user/verify/email")
    },
    statusPassword(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/user/verify/password", { params: credentials })
    },
    resendEmailVerify(credentials) {
        ApiService.setHeader();
        return ApiService.post("v2/user/verify/email", credentials)
    },
    resendPasswordVerify(credentials) {
        ApiService.setHeader();
        return ApiService.post("v2/user/verify/password", credentials)
    },
    changePasswordVerify(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/user/verify/password", credentials)
    },


    // -----API DATA AKSES ACCOUNT------
    getKomoditasAkses() {
        ApiService.setHeader();
        return ApiService.get("v2/user/komoditas")
    },
    getTipeAkses(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/user/tipe", { params: credentials })
    },
    getCompanyAkses(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/user/company", { params: credentials })
    },
}